@import '../../../assets/styles/variables.module.scss';

.report-modal-wrapper {
    .flex-row {
        margin: 0 $spacing-standard $spacing-xs;
        .label-value-container {
            flex-grow: 1;
            flex-basis: 0;
            font-size: $font-size-small;
            &.column {
                display: flex;
                flex-flow: column nowrap;
            }
            .label {
                font-weight: bold;
                margin-right: $spacing-xs;
            }
            .blue {
                color: $color-blue-accent;
            }
        }
        .summary-wrapper {
            text-align: right;
            font-size: $font-size-small;
            > :nth-child(n) {
                margin: 0 $spacing-xxs;
                i {
                    margin-right: $spacing-xxs;
                }
                .red {
                    color: $color-time-lapse-red;
                }
            }
        }
    }
}

.report-modal-map-link {
    margin-left: $spacing-small;
    margin-top: $spacing-standard;
    margin-right: $spacing-small;
    font-size: $font-size-small;
    height: $font-size-small;
    line-height: $font-size-small;
    color: $color-blue-accent;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-bottom: 1px;
    border-bottom: 2px solid transparent;
    &:hover {
        border-color: $color-blue-accent;
    }
}