.modal-wrapper {
    .modal-title {
        text-transform: capitalize;
        text-align: left;
        font-size: $font-size-large;
        font-weight: 600;
        margin-bottom: $spacing-standard;
        padding: $spacing-xl $spacing-standard 0;

        &.with-button {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
        &.centered {
            text-align: center;
        }
    }
}

.modal-body {
    width: 100%;
    text-align: left;
    font-size: $font-size-small;
    padding: $spacing-small $spacing-standard;
    &.confirmation {
        margin-bottom: $spacing-large;
    }
    span .highlighted-text {
        font-weight: 700;
    }
    p {
        padding-bottom: $spacing-standard;
    }
    .modal-title {
        padding: 0;
    }
}

.modal-button-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: $spacing-xl;
    button {
        margin: $spacing-small;
    }
}