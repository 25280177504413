@import '../../assets/styles/variables.module.scss';

.job-tracker-wrapper {
    padding: $spacing-standard $spacing-standard 0;
    .flex-row {
        margin-bottom: $spacing-standard;
        .select-wrapper{
            width: 200px;
            margin-right: $spacing-large;
        }
    }
    .grid-header {
        display: flex;
        flex-flow: row wrap;
        justify-content: stretch;
        border: 1px solid $color-grid-border;
        border-bottom: none;
        background-color: $color-grid-accent;
        padding: $spacing-xs;
        padding-bottom: 0;
        .header-left, .header-right, .header-middle {
            margin-bottom: $spacing-xs;
            flex-basis: 0;
            flex-grow: 1;
            align-items: center;
        }
        .header-left{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            flex-shrink: 0;
        }

        .header-middle {
            display: flex;
            flex-flow: row;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 1;
            width: 300px;
            min-width: 300px;

            .legend-wrapper {
                display: flex;
                flex-flow: row wrap;
                flex-shrink: 0;
                justify-content: space-between;
                margin-right: $spacing-large;
                .rec-date-wrapper {
                    margin: 0 $spacing-xxs;
                    font-size: $font-size-xxs;
                    padding: $spacing-xxs;
                    &.yellow {
                        background-color: $color-time-lapse-yellow;
                    }
                    &.orange {
                        background-color: $color-time-lapse-orange;
                    }
                    &.red {
                        background-color: $color-time-lapse-red;
                    }
                }
            }
        }

        .header-right {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            font-size: $font-size-xs;
            .summary-wrapper {
                text-align: right;
                > :nth-child(n) {
                    margin: 0 $spacing-xxs;
                    i {
                        margin-right: $spacing-xxs;
                    }
                    .red {
                        color: $color-time-lapse-red;
                    }
                }
                > :first-child {
                    margin-right: $spacing-standard;
                }
            }
            .summary-total {
                text-align: right;
            }
        }
    }
    
    .grid-wrapper {
        border: 1px solid $color-grid-border;
    }
    .job-tracker-grid {
        .ag-cell {
            line-height: $spacing-standard;
            padding: $spacing-standard;
        }
    }
    
}