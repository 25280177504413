@import '../../../assets/styles/variables.module.scss';

.file-upload {
    display:flex;
    flex-flow: row wrap;
    justify-content: stretch;

    .upload-left, .upload-right {
        flex-basis: 0;
        flex-grow: 1;
        margin-top: $spacing-small;
        margin-bottom: $spacing-small;
        min-height: 300px;
        min-width: 200px;
    }
    .upload-left {
        margin-right: $spacing-xs;
        margin-left: $spacing-xs;
        background-color: $color-dropzone-grey;
        border-radius: 20px;
        border: 3px dashed $color-dark-grey;
        cursor: pointer;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        svg {
            // color: $color-light-blue;
            font-size: 3em;
        }
        div.text {
            width: 100%;
            // color: $color-dark-blue;
            text-align: center;
        }
        div.info {
            width: 100%;
            // color: $color-dark-grey;
            font-size: $font-size-xs;
            text-align: center;
            margin-bottom: $spacing-small;
        }
        > :nth-child(n) {
            margin: $spacing-small;
        }
    }
    .upload-right {
        margin-left: $spacing-xs;
        margin-right: $spacing-xs;
        display:flex;
        flex-flow: column nowrap;
        max-width: 400px;
        .header {
            font-size: $font-size-xs;
            font-weight: 600;
            margin-left: 0;
            margin-top: $spacing-standard;
            margin-bottom: $spacing-small;
        }
        .file-preview {
            font-weight: 300;
            padding: $spacing-xxs $spacing-small;
            display:flex;
            flex-flow:row nowrap;
            justify-content: space-between;
            span:first-child {
                width: calc(100% - 25px);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            span:last-child {
                cursor: pointer;
            }
            &.error {
                color: red;
            }
        }
        .no-files {
            color: $color-steel-grey;
            padding: $spacing-xxs $spacing-small;
        }
    }

}
.modal-body {
    .file-list {
        margin-left: $spacing-large;
    }
}