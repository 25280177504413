
.form {
    width: 100%;
    .form-row {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: stretch;
        align-items: flex-start;
        flex-grow: 1;
        flex-shrink: 0;
        .form-control{
            margin: $spacing-xs $spacing-standard;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 200px;
            &.no-margin {
                margin: 0;
            }
            &.date-picker {
                flex-basis: 200px;
            }
            .checkbox-controls {
                margin-right: $spacing-xl;
            }
            &.autocomplete {
                .MuiInputLabel-formControl {
                    top: -10px;
                    &.Mui-focused, &.MuiInputLabel-shrink{
                        top: 0;
                    }
                }
                .MuiIconButton-root {
                    padding: 2px;
                }
            }
            &.placeholder {
                min-width: 0;
            }
            &.checkbox {
                flex-basis: 60px;
                flex-grow: 0;
            }
        }
        &.align-center {
            align-items: center;
        }
    }
    &.margin-bottom{
        margin-bottom: $spacing-standard;
    }
    &.margin-top{
        margin-top: $spacing-standard;
    }
}

.MuiFormControl-root {
    width: 100%;
}