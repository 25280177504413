.ag-cell {
    .icon-wrapper {
        height: 100%;
        display:flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        &.align-left {
            justify-content: flex-start;
        }
        &.align-right {
            justify-content: flex-end;
        }
        &.align-center {
            justify-content: center;
        }
        &.delete {
            cursor: pointer;
        }
        .cursor-pointer {
            cursor: pointer;
        }
    }

    &.receivedDate {

        &.error {
            background-color: $color-time-lapse-red;
        }
        &.warn{
            background-color: $color-time-lapse-orange;
        }
        &.caution {
            background-color: $color-time-lapse-yellow;
        }
    }

    &.category {
        &.cable-replmts {
            background-color: $color-cable-replacement;
        }
        &.resi-service {
            background-color: $color-residential-service;
        }
        &.comm-business {
            background-color: $color-commercial-business;
        }
        &.forced-reloc {
            background-color: $color-forced-relocation;
        }
        &.new-build-legacy- {
            background-color: $color-new-build
        }
        &.greenfield {
            background-color: $color-greenfield;
        }
        &.drop-reduct {
            background-color: $color-drop-reduct;
        }
        &.pole-transfer {
            background-color: $color-pole-transfer;
        }
        &.pole-transfer-2 {
            background-color: $color-pole-transfer-2;
        }
        &.fiber-customer {
            background-color: $color-fiber-customer;
        }
        &.storm-damage {
            background-color: $color-storm-damage;
        }
        &.emergency-call-out {
            background-color: $color-emergency-call-out;
        }
        &.mdu {
            background-color: $color-mdu;
        }
        &.dsw-walkout {
            background-color: $color-dsw-walkout;
        }
        &.node-reduction {
            background-color: $color-node-reduction;
        }
    }
    &.cursor-pointer {
        cursor: pointer;
    }
    &.number-column {
        text-align: right;
        &.aerialEstimated, &.undergroundEstimated {
            color: $color-red;
        }
    }
}

.ag-row {
    box-sizing: border-box;
    &.status {
        // border-bottom: 8px solid transparent;
        &.rush {
            background-color: $color-rush;
        }
        &.hold {
           background-color: $color-hold;
        }
        &.cancelled {
            background-color: $color-cancelled;
        }
        &.no-access {
            background-color: $color-no-access;
        }
        &.trim-tree {
            background-color: $color-trim-tree;
        }
        &.gig {
            background-color: $color-gig;
        }
        &.other {
            background-color: $color-other;
        }
        &.temped-out {
            background-color: $color-temped-out;
        }
        &.permit-needed {
            background-color: $color-permit-needed;
        }
        &.no-po {
            background-color: $color-no-po;
        }
    }
    &.non-ntp-job-code {
        background-color: $color-non-ntp-job-code;
    }
}


.ag-theme-material {
    --ag-material-accent-color: #2D62AB;
    .ag-layout-auto-height {
        .ag-center-cols-container, .ag-center-cols-clipper {
            min-height: 160px;
        }
    }
    .ag-simple-filter-body-wrapper {
        background-color: $color-white;
    }
    .ag-header-row {
        background-color: $color-grid-accent;
        font-weight: 900;
        font-size: $font-size-small;

    }
    .ag-cell {
        // line-height: $spacing-standard;
        padding-left: $spacing-standard;
        padding-right: $spacing-standard;
    }
    .ag-header-cell {
        padding-left: $spacing-standard;
        padding-right: $spacing-standard;
    }
}