@import '../../../assets/styles/variables.module.scss';

.wrapper-drawer {
    position: relative;
    .menu-wrapper.MuiBox-root {
        ul.MuiList-root {
            .MuiButtonBase-root {
                padding-left: $spacing-xxl;
            }
        }
    }
    .footer-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: right;
        padding: $spacing-xxs $spacing-xs;
    }
}