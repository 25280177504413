// Color Palette
$color-red: #F20519;
$color-orange: #dd550c;
$color-green: #05A610;
$color-blue: #63B6DC;
$color-blue-main: #2D62AB;
$color-blue-accent: #5E86BD;
$color-black: #000000;
$color-dark-grey: #404040;
$color-steel-grey: #AFAFAF;
$color-silver: #D3D3D3;
$color-dropzone-grey: #FCF9F9;
$color-white: #ffffff;
$color-grid-accent: #F5F5F5;
$color-grid-border: #DDDDDD;

// Grid Date Received Colors
$color-time-lapse-yellow: #FEFE55;
$color-time-lapse-orange: #F0A83C;
$color-time-lapse-red: #EB7A76;

// Grid Colors Categories
$color-cable-replacement: rgb(152, 251, 152);
$color-residential-service: rgb(255, 255, 224);
$color-commercial-business: rgb(255, 179, 84);
$color-forced-relocation: rgb(142, 239, 244);
$color-new-build: rgb(230, 230, 250);
$color-greenfield: rgb(173, 255, 47);
$color-drop-reduct: rgb(255, 228, 225);
$color-pole-transfer: rgb(255, 255, 0);
$color-pole-transfer-2: rgb(255, 215, 0);
$color-fiber-customer: rgb(0, 255, 255);
$color-storm-damage: rgb(223, 192, 159);
$color-emergency-call-out: rgb(255, 192, 203);
$color-mdu: rgb(162, 217, 206);
$color-dsw-walkout: rgb(239, 153, 239);
$color-node-reduction: rgb(219, 197, 153);

// Grid Colors Status (incomplete)
$color-rush: #90EE8F;
$color-hold: #FEC0CA;
$color-cancelled: #AED8E6;
$color-no-access: #FAC552;
$color-trim-tree: #FEFF00;
$color-gig: #FF0100;
$color-other: #EE81ED;
$color-temped-out: #CCFF66;
$color-permit-needed: #B59D5F;
$color-no-po: #A293FF;

// Non-Ntp job code
$color-non-ntp-job-code: #f9f9a9;

// Standard Spacings
$spacing-xxl: 50px;
$spacing-xl: 32px;
$spacing-large: 25px;
$spacing-standard: 16px;
$spacing-small: 12px;
$spacing-xs: 7px;
$spacing-xxs: 3px;

// Font sizes
$font-size-xl: 36px;
$font-size-large: 24px;
$font-size-standard: 18px;
$font-size-small: 14px;
$font-size-xs: 12px;
$font-size-xxs: 10px;
$font-size-xxxs: 8px;

// Border Radius
$border-radius-small: 7px;

// MISC
$header-height: 80px;

// Fonts
$font-header: 'Oswald', sans-serif;
$font-main: 'Roboto', sans-serif;

:export {
    color_red: $color-red;
    color_orange: $color-orange;
    color_green: $color-green;
    color_blue: $color-blue;
    color_blue_main: $color-blue-main;
    color_blue_accent: $color-blue-accent;
    color_black: $color-black;
    color_dark_grey: $color-dark-grey;
    color_steel_grey: $color-steel-grey;
    color_silver: $color-silver;
    color_dropzone_grey: $color-dropzone-grey;
    color_white: $color-white;
    color_grid_accent: $color-grid-accent;
    color_grid_border: $color-grid-border;
    font_size_large: $font-size-large;
    font_size_standard: $font-size-standard;
    font_size_small: $font-size-small;
    font_size_xs: $font-size-xs;
    font_size_xxs: $font-size-xxs;
    spacing_xxl: $spacing-xxl;
    spacing_xl: $spacing-xl;
    spacing_large: $spacing-large;
    spacing_standard: $spacing-standard;
    spacing_xs: $spacing-xs;
    spacing_xxs: $spacing-xxs;
}
