@import '../../assets/styles/variables.module.scss';

.production-report-wrapper {
    padding: $spacing-standard;
    .flex-row {
        margin-bottom: $spacing-standard;
        .select-wrapper{
            width: 200px;
            margin-right: $spacing-large;
        }
    }
    .grid-wrapper {
        border: 1px solid $color-grid-border;
    }
}