@import '../../../assets/styles/variables.module.scss';

.job-map-info-card {
    &.list-view {
        width: 100%;
        font-size: $font-size-small;
        padding: $spacing-xs $spacing-small;
        &.active, &:hover {
            background-color: $color-silver;
            cursor: pointer;
        } 
    }
    .map-info-header {
        font-size: $font-size-large;
        color: $color-blue-main;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .flex-row {
        span {
            margin-right: $spacing-standard;
        }
        .map-action {
            color: $color-green;
            margin-right: $spacing-xs;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}