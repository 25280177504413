@import '../../../assets/styles/variables.module.scss';

.loading-icon-wrapper {
    display:none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba($color-silver, .5);
    z-index: 100000;
    justify-content: center;
    align-items: center;
    .icon{
        height: 100px;
        width: 100px;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        stroke: $color-blue-main;
        path {
            stroke: $color-blue-main;
            fill: $color-blue-main;
        }
    }
    &.loading-indicator {
        display: flex;
    }
}

@keyframes spin {
    from {
        transform:rotate(360deg);
    }
    to {
        transform:rotate(0deg);
    }
}
