.styled-accordion.MuiAccordion-root {
    box-shadow: none;
    border-top: 1px solid $color-grid-border;
    // margin: $spacing-standard 0;
    &:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &:before{
        background-color: transparent;
    }
    &:last-child {
        border-bottom: 1px solid $color-grid-border;
    }
    &.Mui-expanded {
        margin-top: 0;
        margin-bottom: $spacing-standard;
    }
    .MuiAccordionSummary-root {
        padding-left: $spacing-xl;
        padding-right: $spacing-xl;
        width: 100%;
    }
}
