@import '../../assets/styles/variables.module.scss';

.map-view-wrapper {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    .left-list-wrapper {
        width: 400px;
        padding-top: $spacing-small;
        height: 100%;
        overflow-y: scroll;
        .flex-row.margin-bottom {
            margin: 0 $spacing-small $spacing-small;
        }
        .enable-current-location-wrapper {
            cursor: pointer;
            &.active {
                color: $color-blue;
            }
        }
        .google-directions-wrapper {
            padding: 0 $spacing-small;
            font-size: $font-size-xs;
            margin-bottom: $spacing-small;
        }
    }
    .map-wrapper {
        flex-grow: 1;
        flex-basis: 0;
        height: 100%;
    }
}
.marker-label {
    font-size: $font-size-xl;
    position: relative;
    left: 12px;
    top: 12px;
    &.big-label {
        left: 17.5px;
        top: 17.5px;
    }
}
