@import '../../../assets/styles/variables.module.scss';


.header-wrapper {
    width: 100%;
    height: $header-height;
    background-color: $color-blue-main;
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    padding: 0 $spacing-standard;
    position: fixed;
    z-index: 100;
    .header-section {
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        align-items: center;
        &.left {
            flex-flow: row;
            justify-content: flex-start;
            .logo-wrapper {
                cursor: pointer;
            }
        }
        &.middle {
            flex-flow: row;
            justify-content: center;
            margin: 0 $spacing-standard;
            span {
                font-family: $font-header;
                font-size: $font-size-large;
                color: $color-white;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        &.right {
            flex-flow: row;
            justify-content: flex-end;
            color: $color-white;
            font-size: $font-size-xl;

            .username-container {
                font-size: $font-size-small;
                margin-right: $spacing-standard;
            }
            .user-icon {
                cursor: pointer;
            }
        }
    }
}

.header-icons {
    padding: 7px; float: left;
}
