@import '../../assets/styles/variables.module.scss';

.user-details-wrapper {
    padding: $spacing-standard;
    .flex-header {
        margin-left: $spacing-standard;
        margin-bottom: $spacing-xs;
    }
    .flex-row {
        .button-wrapper {
            margin-bottom: $spacing-standard;
            > :nth-child(n) {
                margin-left: $spacing-standard;
            }
        }
    }
}