@import '../../assets/styles/variables.module.scss';

.production-report-wrapper {
    .grid-header {
        display: flex;
        flex-flow: row wrap;
        justify-content: stretch;
        border: 1px solid $color-grid-border;
        border-bottom: none;
        background-color: $color-grid-accent;
        padding: $spacing-xs;
        padding-bottom: 0;
        font-size: $font-size-small;
        .header-left, .header-right, .header-middle {
            margin-bottom: $spacing-xs;
            flex-basis: 0;
            flex-grow: 1;
            align-items: center;
        }
        .header-left{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            flex-shrink: 0;
        }
        .header-right{
            display: flex;
            flex-flow: row wrap;
            align-items: right;
            justify-content: end;
            flex-shrink: 0;
            text-align: right;
            margin-right: 1em;
        }
        .summary-wrapper {
            text-align: right;
            > :nth-child(n) {
                margin: 0 $spacing-xxs;
                i {
                    margin-right: $spacing-xxs;
                }
                .red {
                    color: $color-time-lapse-red;
                }
            }
            > :first-child {
                margin-right: $spacing-standard;
            }
        }
        .summary-total {
            text-align: right;
        }
    }

}