@import '../../assets/styles/variables.module.scss';
$sticky-header: 68.5px;

.job-details-flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    overflow-x: hidden;
    .sticky-header {
        position: fixed;
        height: $sticky-header;
        top: $header-height;
        width: 100vw;
        z-index: 10;
        display: flex;
        flex-flow: row nowrap;
        justify-content: stretch;
        background-color: transparent;
        .sticky-header-drawer-spacer {
            background-color: transparent;
            flex-shrink: 0;
        }
        .flex-row {
            flex-grow: 1;
            flex-shrink: 1;
            background-color: white;
            overflow-x: hidden;
            flex-wrap: nowrap;
        }
    }
    .scrollable-section {
        padding-top: $sticky-header;
    }
    .job-details-wrapper, .sticky-header .job-details-wrapper {
        flex-grow: 1;
        overflow-x: hidden;
    }
    > .job-details-wrapper > .MuiPaper-root:first-child {
        margin-top: 68.5px;
    }
    .card-container {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: stretch;
    }
    .sub-header {
        margin-left: $spacing-standard;
        font-size: $font-size-small;
        font-weight: 800;
        color: $color-blue-main;
        text-align: left;
    }
    .grid-wrapper {
        margin: $spacing-standard;
        &:first-child {
            margin-top: 0;
        }
    }
}

.locates-button-wrapper {

    > button:nth-child(n) {
        margin: 0 $spacing-standard $spacing-standard;
    }
    > :nth-child(n) {
        margin: 0 $spacing-standard;
        &.button-wrapper > :nth-child(n) {
            margin-right: $spacing-standard;
        }
        button {
            margin-bottom: $spacing-standard;
        }
    }
}