@import '../../../../assets/styles/variables.module.scss';

.document-container {
    padding: 0 $spacing-standard;
    margin-bottom: $spacing-standard;

    .document-section {
        .section-title {
            font-size: $font-size-standard;
            font-weight: 600;
            margin: $spacing-standard 0;
        }
    
        .file-row {
            padding: $spacing-small;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: stretch;
            border-top: 1px solid $color-grid-border;
            span.icon-name-wrapper {
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 0;
                font-size: $font-size-xs;
                display:flex;
                flex-flow: row nowrap;
                align-items: center;
                span {
                    margin-left: $spacing-small;
                }
            }
            .upload-date {
                flex-basis: 30%;
                margin-right: $spacing-standard;
                font-size: $font-size-xs;
            }
            .download-wrapper {
                flex-basis: 80px;
                display: flex;
                justify-content: flex-end;
                svg.download-icon {
                    color: $color-blue-main;
                    cursor: pointer;
                    margin-left: $spacing-standard;
                }
            }
    
            &:last-child {
                border-bottom: 1px solid $color-grid-border;
            }
        }
    }
}

$stamp-row-height: 70px;
$wrapper-offset: $header-height + $spacing-xxl + $spacing-small + $stamp-row-height;
.pdftron-wrapper {
    position: fixed;
    width: 100vw;
    height: calc(100vh - $header-height);
    z-index: 100;
    top: $header-height;
    left: 0;
    background-color: $color-white;

    .flex-row{
        height: $spacing-xxl;
        margin: 0 $spacing-standard;
    }
    .document-viewer-container {
        height: calc(100vh - $wrapper-offset);
    }

    .stamp-row {
        display: flex;
        flex-flow: row nowrap;
        overflow-x: scroll;
        height: $stamp-row-height;
        padding-bottom: $spacing-small;
        .stamp-icon {
            height: 100%;
            padding: $spacing-small;
            cursor: pointer;
            &.active {
                background-color: $color-silver;
            }
        }
    }
}