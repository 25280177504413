@import '../../assets/styles/variables.module.scss';


.user-list-wrapper {
    padding: $spacing-standard;
    .flex-row {
        .button-wrapper {
            margin-bottom: $spacing-standard;
        }
    }
    .grid-wrapper {
        border: 1px solid $color-grid-border;
    }
}