@import '../../../assets/styles/variables.module.scss';
@import '../../../assets/styles/mixins.scss';

.modal-background-noclick {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: rgba($color-silver, .5);
    .modal-wrapper {
        position: relative;
        min-width: 320px;
        max-width: 100vw;
        max-height: 100vh;
        overflow-y: auto;
        background-color: $color-white;
        z-index: 1005;
        @include bigham-drop-shadow($color-steel-grey);
        &.small {
            width: 30%;
        }
        &.medium {
            width: 50%;
        }
        &.large {
            width: 80%;
        }
        .close-icon {
            position: absolute;
            top: $spacing-standard;
            right: $spacing-standard;
            cursor: pointer;
        }
    }
}
