@import '../../assets/styles/variables.module.scss';

.application-wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    .component-wrapper {
        width: 100%;
        height: calc(100vh - $header-height);
        margin-top: $header-height;
    }
}