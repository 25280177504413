@import './variables.module.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font-main;
}

body {
    font-size: $font-size-standard;
    &.disable-scroll {
        overflow: hidden;
    }
}

.clickable-link {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: $font-size-small;
    color: $color-blue-accent;
    cursor: pointer;
    > :nth-child(n) {
        margin-right: $spacing-large;
    }
    > :first-child {
        margin-right: $spacing-xs;
    }
}

.flex-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    &.no-wrap {
        flex-flow: row nowrap;
    }
    &.reverse-wrap {
        flex-wrap: wrap-reverse;
    }
}

.flex-column {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.flex-row, .flex-column {
    &.space-between {
        justify-content: space-between;
    }
    &.space-evenly {
        justify-content: space-evenly;
    }
    &.flex-start {
        align-items: flex-start;
    }
    &.flex-end {
        justify-content: flex-end;
    }
    &.center {
        justify-content: center;
        width: 100%;
    }
    &.standard-padding {
        padding: $spacing-standard;
    }
}

.flex-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.component-title {
    font-size: $font-size-standard;
    font-weight: 800;
    color: $color-blue-main;
    text-align: left;
}



.MuiInputBase-root, .MuiFormLabel-root, .MuiMenuItem-root {
    font-family: $font-main !important;
}