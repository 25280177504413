@import '../../../assets/styles/variables.module.scss';

.MuiAlert-root {
    cursor: pointer;
    // @include bigham-drop-shadow($color-silver);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    &.MuiAlert-filledError {
        background-color: $color-red;
        color: $color-white;
    }
    &.MuiAlert-filledSuccess {
        background-color: $color-green;
        color: $color-white;
    }
    &.MuiAlert-filledWarning {
        background-color: $color-orange; 
        color: $color-white;
    }
    &.MuiAlert-filledInfo {
        background-color: $color-blue;
        color: $color-white;
    }
    .MuiAlert-message {
        display: flex;
        flex-flow:row nowrap;
        align-items: center;
    }
    .close-icon {
        margin-left: $spacing-standard;
    }
}