@import '../../assets/styles/variables.module.scss';

.splash-page-wrapper {
    height: calc(100vh - $header-height);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    .splash-page-card {
        width: 400px;
        max-width: 100%;
        text-align: center;
        background-color: $color-dropzone-grey;
        padding: $spacing-xl;
    }
}