@import '../../../../assets/styles/variables.module.scss';


.financials-section {
    .job-financials-summary-wrapper {
        padding: $spacing-standard;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: stretch;
    
        .summary-column {
            flex-basis: 0;
            flex-grow: 1;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            max-width: 300px;
            padding: $spacing-standard;
            background-color: $color-dropzone-grey;
            &:last-child {
                margin-right: 0;
            }
            .summary-row {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                font-size: $font-size-xs;
                white-space: nowrap;
                margin-bottom: $spacing-xxs;
                .summary-label {
                    font-weight: bold;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    
    .requested-invoice-wrapper {
        padding-top: $spacing-small;
        padding-bottom: $spacing-small;
        width: 100%;
        display: flex;
        flex-flow: row-reverse wrap-reverse;
        justify-content: stretch;
        flex-basis: 0;
        background-color: $color-dropzone-grey;
        .requested-invoice-summary-grid-wrapper {
            flex-grow: 6;
            flex-shrink: 1;
            flex-basis: 700px;
            position: relative;
            .comments-wrapper {
                margin-left: 42px;
                flex-grow: 1;
                flex-basis: 0;
                font-size: $font-size-small;
                b {
                    margin-right: $spacing-standard;
                }
            }
            .total-wrapper {
                margin-right: 42px;
                flex-grow: 0;
                flex-basis: 200px;
                font-size: $font-size-small;
                text-align: right;
                font-weight: bold;
            }
            .ag-theme-material {
                --ag-background-color: transparent;
                --ag-header-background-color: transparent;
                --ag-control-panel-background-color: white;
                .ag-layout-auto-height {
                    .ag-center-cols-container, .ag-center-cols-clipper  {
                        min-height: 50px;
                    }

                } 
            }
            .grid-wrapper {
                padding-bottom:$spacing-standard;
            }
        }
        .issue-invoice-form-wrapper {
            flex-basis: 300px;
            flex-grow: 1;
            flex-shrink: 1;
            &.form {
                .form-row .form-control {
                    margin: 5px $spacing-standard;
                }
                :nth-child(2).form-row {
                    margin-top: $spacing-standard + 6;
                }
            }
        }
    }
}
