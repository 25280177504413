@import '../../../assets/styles/variables.module.scss';
.footage-card-wrapper {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 250px;
    background-color: $color-grid-accent;
    padding: $spacing-standard;
    margin: $spacing-small;
    position: relative;

    .delete-team-wrapper {
        position: absolute;
        top: $spacing-xxs;
        right: $spacing-xxs;
        cursor: pointer;
    }
}