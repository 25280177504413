@import '../../assets/styles/variables.module.scss';

.MuiDrawer-root {
    .MuiPaper-root {
        background-color: $color-grid-accent;
        .expand-collapse-icon {
            color: $color-blue-main;
            position: absolute;
            z-index: 10;
            right: -25px;
            width: 25px;
            top: calc(50vh - $header-height);
            border: 1px solid $color-grid-border;
            border-left: 0;
            display: flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;
            padding: $spacing-standard 0;
            border-top-right-radius: $border-radius-small;
            border-bottom-right-radius: $border-radius-small;
            cursor: pointer;
            background-color: $color-grid-accent;
            visibility: visible;
            transition: all linear .2s;
        }
    }
}
$button-footer-height: 100px;
.drawer-contents-wrapper {
    padding: $spacing-small $spacing-standard;
    overflow-y: auto;
    height: calc(100vh - $header-height);
    position: relative;
    .drawer-header {
        margin-bottom: $spacing-large;
    }
    .data-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: stretch;
        align-items: center;
        margin: $spacing-small 0;
        .label, .value {
            flex-grow: 1;
            flex-basis: 0;
            text-align: left;
            font-size: $font-size-small;
            width: 50%;
        }
        .label {
            font-weight: 600;
            &.indent {
                margin-left: $spacing-standard;
                margin-right: -$spacing-standard;
            }
        }
        &.flex-start {
            align-items: flex-start;
        }
    }
    .data-row-team {
        display: flex;
        flex-flow: column nowrap;
        justify-content: stretch;
        align-items: start;
        margin: $spacing-small 0;
        .label, .value {
            flex-grow: 1;
            flex-basis: 0;
            text-align: left;
            font-size: $font-size-small;
            width: 50%;
            &.indent {
                margin-left: $spacing-standard;
                margin-right: -$spacing-standard;
            }
        }
        .label {
            font-weight: 600;
        }
        .value {
            margin-top: 5px;
        }
    }
}