@import '../../../assets/styles/variables.module.scss';

.select-filter {
    padding: 16px 12px;
    .MuiFormControlLabel-root {
        margin-right: 0;
    }
}

.gig-renderer {
    padding: $spacing-xs;
    border-radius: 50%;
    background-color: $color-orange;
    height: $spacing-xl;
    width: $spacing-xl;
    min-width: $spacing-xl;
    line-height: $spacing-standard + 2;
    font-size: $font-size-standard;
    text-align: center;
    color: $color-white;
}

.footage-display-renderer {
    .red {
        color: $color-time-lapse-red;
    }
}